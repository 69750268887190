import { useMemo } from "react"
import ContainerMain from "pages/common/ContainerMain"

import AchievementDesktopEn from "images/Company/About/achievement_desktop_en.png"
import AchievementDesktopVi from "images/Company/About/achievement_desktop_vi.png"
import AchievementMobileEn from "images/Company/About/achievement_mobile_en.png"
import AchievementMobileVi from "images/Company/About/achievement_mobile_vi.png"
import { useFormatter } from "helpers/i18n"
import { useAppContext } from "pages/common/layout"
import "./Achievement.scss"

export default function Achievement() {
  const { __, currentLocale } = useFormatter()
  const { isMobile } = useAppContext()

  const achievementImg = useMemo(() => {
    if (currentLocale === "vi") {
      return isMobile ? AchievementMobileVi : AchievementDesktopVi
    } else {
      return isMobile ? AchievementMobileEn : AchievementDesktopEn
    }
  }, [currentLocale, isMobile])

  return (
    <>
      <div className="aaywqvghgq">
        <ContainerMain>
          <div className="qowcunzvdd">
            <div className="fisjzokarx">{`${__({
              defaultMessage: "Notable Achievements",
            })}`}</div>
            <div>
              <img src={achievementImg} style={{ width: "100%" }} />
            </div>
          </div>
        </ContainerMain>
      </div>
    </>
  )
}
